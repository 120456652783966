import { useStaticQuery, graphql } from "gatsby";

const Addresses = ({ children }) => {
  const data = useStaticQuery(graphql`
    query AddressesQuery {
      allFile(filter: { relativePath: { glob: "addresses/*" } }) {
          nodes {
            id
            childMarkdownRemark {
              id
              frontmatter {
                name
                title,
                lat,
                lng,
                image {
                  childImageSharp {
                    fixed(height: 40) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              rawMarkdownBody
            }
          }
      }
    }
  `);

  return children(data.allFile);
};

export default Addresses;