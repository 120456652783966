import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import Image from "gatsby-image";

const StyledAddress = styled.address`
  font-style: inherit;
  > p { 
    margin-top: .2em;
  }
`;

const Address = ({ node }) => (
  <StyledAddress>
    <Image fixed={node.childMarkdownRemark.frontmatter.image.childImageSharp.fixed} />
    <ReactMarkdown source={node.childMarkdownRemark.rawMarkdownBody} />
  </StyledAddress>
);

export default Address;

