import React from "react"
import styled from "styled-components"
import { Container, Box, Grid } from "@material-ui/core"
import { useIntl } from "../../intl";
import Addresses from "./addresses";

const StyledBox = styled(Box)`
  background-color: ${props => props.theme.palette.footer.main};
`

const FooterMessage = styled.span`
  font-weight: 600;
`;

const Footer = () => {
  const intl = useIntl()

  return (
    <StyledBox component="footer">
      <Container maxWidth="lg">
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box mb={2}>
                <FooterMessage>{intl.formatMessage({ id: "footer_msg" })}</FooterMessage>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Addresses />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledBox>
  )
}

export default Footer
