import React from "react";
import AddressesSource from "../../datasources/addresses";
import Address from "../address";
import { Grid } from "@material-ui/core";

const FooterAddresses = () => {
  return (
    <Grid container spacing={2} justify="flex-end">
      <AddressesSource>
        {({ nodes }) => nodes.map((address) => {
          return (
            <Grid item xs={6} lg={4} key={address.id}>
              <Address node={address} />
            </Grid>
          );
        })}
      </AddressesSource>
    </Grid>
  )
}

export default FooterAddresses;